<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0" v-if="filters">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-10 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    :model.sync="filters.search"
                    :placeholder="$t('general.search_filter')"
                    title="general.search"
                ></input-filter>
              </div>
              <div
                  class="col-xl-1 col-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button  type="button" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="filterResult()">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button type="button" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                          @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                <button @click="exportCsrTable" type="button"
                        class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                  {{ $t('general.export_to_excel') }}
                </button>
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12" >
      <dashboard-box :title="$t('csr.csr_list')">
        <template v-slot:preview>
          <data-table
              :bordered="false"
              :borderless="true"
              :fields="tableFields"
              :hover="false"
              :items="formattedItems"
              :no-border-collapse="true"
              :outlined="false"
              :striped="false"
              :table-parent-fields="tableParentFields"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              @hitBottom="onHitBottom"
              @rowClicked="onRowClicked"

          ></data-table>
          <div class="fixed" style="bottom: 20px; right: 40px;" v-if="isUserGranted('Csr', ['create'], false)">
            <div class="w-60px h-60px" @click="createNewCsr">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import csrData from "@/view/pages/csr/csr-data.js";
import { mapGetters, mapMutations } from "vuex";

import csrModule, {
  BASE_URL as CSR_URL,
  ERROR,
  EXPORT,
  ITEMS,
  LOADING,
  MODULE_NAME,
  HANDLE_INFINITE_SCROLL,
  FILTER,
  SET_FILTER
} from "@/core/services/store/csr/csr.module";

import companiesModule, {
  BASE_URL as BASE_URL_COMPANIES,
  GET_ITEMS as GET_COMPANIES,
  ITEMS as COMPANIES,
  MODULE_NAME as MODULE_COMPANIES
} from "@/core/services/store/company/company.module";

import supplierTypeModule, {
  BASE_URL as BASE_URL_SUPPLIER_TYPE,
  GET_ITEMS as GET_SUPPLIER_TYPES,
  ITEMS as SUPPLIER_TYPES,
  MODULE_NAME as MODULE_SUPPLIER_TYPE
} from "@/core/services/store/supplier-type/supplierType.module";

import processModule, {
  BASE_URL as BASE_URL_CSR_PROCESS,
  GET_ITEMS as GET_CSR_PROCESSES,
  ITEMS as CSR_PROCESSES,
  MODULE_NAME as MODULE_CSR_PROCESS
} from "@/core/services/store/csr-process/csrProcess.module";

import store from "@/core/services/store";
import {SET_ITEMS} from "@/core/services/store/user/user.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import * as _ from "lodash";


const _MODULE_NAME = MODULE_NAME;
const _MODULE_COMPANIES = MODULE_COMPANIES;
const _MODULE_CSR_PROCESS = MODULE_CSR_PROCESS;
const _MODULE_SUPPLIER_TYPE = MODULE_SUPPLIER_TYPE;

export default {
  name: "CSRIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, csrModule)
    registerStoreModule(_MODULE_COMPANIES, companiesModule)
    registerStoreModule(_MODULE_SUPPLIER_TYPE, supplierTypeModule)
    registerStoreModule(_MODULE_CSR_PROCESS, processModule)
  },
  data() {
    return {
      // Filters & pagination
      infiniteId:1,
      defaultFilter: {
        per_page: 100,
        page: 1,
        search: "",
      }
    };
  },
  computed: {
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    filter() {
      return store.getters[_MODULE_NAME + '/' + FILTER];
    },
    companies() {
      return store.getters[_MODULE_COMPANIES + '/' + COMPANIES];
    },
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    auditDataOptions() {
      return csrData.auditData;
    },
    yesNoOptions() {
      return csrData.yesNo;
    },
    disneyLicences() {
      return csrData.disneyLicences;
    },
    joinLifeOptions() {
      return csrData.joinLife;
    },
    certificateOptions() {
      return csrData.certificates;
    },
    supplierTypeOptions() {
      return store.getters[_MODULE_SUPPLIER_TYPE + '/' + SUPPLIER_TYPES];
    },
    processOptions() {
      return store.getters[_MODULE_CSR_PROCESS + '/' + CSR_PROCESSES];
    },
    tableParentFields() {
      return [
        {
          'parent': true,
          'label': "csr.supplier_company",
          'colspan': 1,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'parent': true,
          'label': "csr.company_title",
          'colspan': 1,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'parent': true,
          'label': "csr.process",
          'colspan': 2,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'parent': true,
          'label': "csr.company_audit_result",
          'colspan': 3,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'parent': true,
          'label': "csr.disney_licences",
          'colspan': 2,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'parent': true,
          'label': "csr.join_life_title",
          'colspan': 11,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        }];
    },
    tableFields() {
      let items = [
        {
          'key': 'supplier_company',
          'label': "",
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
          "stickyColumn": true, 
          "isRowHeader": true, 
        },
        {
          'key': 'company',
          'label': "",
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'supplier_type',
          'label': this.$t('csr.supplier_type'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'process',
          'label': this.$t('csr.process'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'audit_social_id',
          'label': this.$t('csr.audit_social'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'audit_environment',
          'label': this.$t('csr.audit_environment'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'audit_metal',
          'label': this.$t('csr.audit_metal'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'disney_id',
          'label': this.$t('csr.disney'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        },
        {
          'key': 'licence',
          'label': this.$t('csr.licence'),
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        }
      ];

      csrData.joinLife.forEach(item => {
        items.push({
          'key': item.name,
          'label': item.name,
          'class' : "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass' : "cursor-pointer",
        })
      })

      return items;
    },
    items() {
      return store.getters[_MODULE_NAME + '/' + ITEMS];
    },
    formattedItems() {
      let results = [];
      if (this.items === null || this.items.data === null || this.items.data === undefined) return [];

      let self = this;
      let items = this.items.data;

      items.forEach(item => {
        let companyNames = [];
        let tmpName = null;

        if (this.companies) {
          item.company_id.forEach(companyItem => {
            tmpName = this.companies.find(subItem => subItem.id == companyItem);
            if (tmpName) {
              companyNames.push(tmpName.name);
            }
          })
        }


        let processNames = [];
        if (item.process && item.process.process_id && item.process.process_id.length && this.processOptions) {
          item.process.process_id.forEach(mainSubItem => {
            tmpName = this.processOptions.find(subItem => subItem.id == mainSubItem);
            if (tmpName) {
              processNames.push(tmpName.translations[0].name);
            }
          })
        }

        let supplierTypeNames = [];
        if (item.process && item.process.supplier_type_id && item.process.supplier_type_id.length && this.supplierTypeOptions) {
          item.process.supplier_type_id.forEach(mainSubItem => {
            tmpName = this.supplierTypeOptions.find(subItem => subItem.id == mainSubItem);
            if (tmpName) {
              supplierTypeNames.push(tmpName.translations[0].name);
            }
          })
        }

        let auditSocial = null;
        if (item.audit_results && item.audit_results.audit_social_id && this.auditDataOptions) {
          tmpName = this.auditDataOptions.find(subItem => subItem.id == item.audit_results.audit_social_id);
          if (tmpName) {
            auditSocial = tmpName.name;
          }
        }

        let auditEnvironment = null;
        if (item.audit_results && item.audit_results.audit_environment_id && this.auditDataOptions) {
          tmpName = this.auditDataOptions.find(subItem => subItem.id == item.audit_results.audit_environment_id);
          if (tmpName) {
            auditEnvironment = tmpName.name;
          }
        }

        let auditMetal = null;
        if (item.audit_results && item.audit_results.audit_metal_id && this.auditDataOptions) {
          tmpName = this.auditDataOptions.find(subItem => subItem.id == item.audit_results.audit_metal_id);
          if (tmpName) {
            auditMetal = tmpName.name;
          }
        }

        let disneyName = null;
        if (item.disney_licences && item.disney_licences.disney_id) {
          tmpName = this.yesNoOptions.find(subItem => subItem.id == item.disney_licences.disney_id);
          if (tmpName) {
            disneyName = tmpName.name;
          }
        }

        let licenceName = null;
        if (item.disney_licences && item.disney_licences.license_id) {
          tmpName = this.disneyLicences.find(subItem => subItem.id == item.disney_licences.license_id);
          if (tmpName) {
            licenceName = tmpName.name;
          }
        }

        let newItem = {
          'id': item.id,
          'supplier_company': item.supplier_company.name,
          'company': companyNames.join(", "),
          'supplier_type': supplierTypeNames.join(", "),
          'process': processNames.join(", "),
          'audit_social_id': auditSocial,
          'audit_environment': auditEnvironment,
          'audit_metal': auditMetal,
          'disney_id': disneyName,
          'licence': licenceName,
        };

        if (item.join_life && item.join_life.length) {
          self.joinLifeOptions.forEach(mainSubItem => {
            newItem[mainSubItem.name] = item.join_life && item.join_life.includes(mainSubItem.id.toString()) ? self.$t('csr.exist') : self.$t('csr.not_exist');
          })
        }
        results.push(newItem)
      })

      return results;
    },
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
      'loading': LOADING,
    }),
    setFilter() {
      this.$store.commit(_MODULE_NAME + '/' + SET_FILTER, {
        per_page: 25,
        page: 1,
        search: "",
      })
    },
    getSupplierTypes() {
      this.$store.dispatch(_MODULE_SUPPLIER_TYPE + '/' + GET_SUPPLIER_TYPES, {
        url: BASE_URL_SUPPLIER_TYPE,
        filters: {}
      })
    },
    getCsrProcesses() {
      this.$store.dispatch(_MODULE_CSR_PROCESS + '/' + GET_CSR_PROCESSES, {
        url: BASE_URL_CSR_PROCESS,
        filters: {}
      })
    },
    getCompanies() {
      this.$store.dispatch(_MODULE_COMPANIES + '/' + GET_COMPANIES, {
        url: BASE_URL_COMPANIES,
        filters: {}
      })
    },
    createNewCsr() {
      if(this.isUserGranted('Csr', ['create'])) {
        this.$router.push({name: 'csr.new'});
      }
    },
    onRowClicked(item, index, event) {
      if(this.isUserGranted('Csr', ['update', 'view'])) {
        this.$router.push({name: 'csr.edit', params: {id: item.id,}});
      }
    },
    filterResult() {
      let self = this;
      self.page = 1;
      self.filters.page = 1;
      self.infiniteId++;
      self.onHitBottom(null);
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      
      if(this.isUserGranted('Csr', ['viewAny'])) {
        let filters = _.cloneDeep(this.filters);
        this.$store.dispatch(_MODULE_NAME + '/' + HANDLE_INFINITE_SCROLL, {
          filters: filters,
          url: CSR_URL,
          '$state': $state,
        }).then(result => {
          if(result.status) {
            if ($state) {
              $state.loaded();
            }
          }else{
            if ('end' in result) {
              if ($state) {
                $state.complete();
              }
            }
          }
        })
      }
    },
    resetFilters() {
      this.setFilter()
    },
    setFilters() {
      this.setItems(null);
      this.page = 1;
      this.filters.page = 1;
      this.onHitBottom(null);
    },
    setItems(items) {
      this.$store.commit(_MODULE_NAME + '/' + SET_ITEMS, items)
    },
    exportCsrTable() {
      let filters = _.cloneDeep(this.filters);
      this.$store.dispatch(_MODULE_NAME + '/' + EXPORT, {
        filters: filters,
      }).then(result => {
        if(result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'csr_' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
    },
    
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("csr.csr_index")}
    ]);

    if (!this.filter) {
      this.resetFilters();
    }

    if (this.isUserGranted('Csr', ['viewAny'])) {
      this.setItems(null)
      this.getCompanies();
      this.getSupplierTypes();
      this.getCsrProcesses();
      
      setTimeout(this.onHitBottom, 500);
    }
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>

<style lang="scss">
  .b-table-sticky-header
  > .table.b-table
  > thead
  > tr
  > th{
    background-color: white !important;
  }
  .b-table-sticky-header {
    max-height: 500px;
}
.b-table-sticky-header
  > .table.b-table
  > tbody
  > tr
  > th{
    background-color: white !important;
  }
</style>